var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"750px"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('v-card',[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 pl-5"},[_vm._v(" Objekt bearbeiten ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Label","required":"","rules":[_vm.rules.required]},model:{value:(_vm.editedItem.label),callback:function ($$v) {_vm.$set(_vm.editedItem, "label", $$v)},expression:"editedItem.label"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.$store.getters.getSubCategories,"loading":_vm.$store.state.rights.isLoading,"label":"Objekt Typ"},model:{value:(_vm.editedItem.properties.objectType),callback:function ($$v) {_vm.$set(_vm.editedItem.properties, "objectType", $$v)},expression:"editedItem.properties.objectType"}})],1)],1),(
              _vm.$store.getters.getMainCategory(_vm.editedItem.objectType) ==
              'Stempelgeraete'
            )?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Radius (in meter)","type":"number"},model:{value:(_vm.editedItem.properties.radius),callback:function ($$v) {_vm.$set(_vm.editedItem.properties, "radius", _vm._n($$v))},expression:"editedItem.properties.radius"}})],1)],1):_vm._e(),(
              _vm.$store.getters.getMainCategory(_vm.editedItem.objectType) ==
              'NEW Gebäude'
            )?_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Beschreibung","counter":"","maxlength":"120","full-width":"","single-line":"","rows":"2"},model:{value:(_vm.editedItem.properties.description),callback:function ($$v) {_vm.$set(_vm.editedItem.properties, "description", $$v)},expression:"editedItem.properties.description"}})],1)],1):_vm._e(),(
              this.editedItem.hauptKategorie == 'In Planung' ||
              this.editedItem.hauptKategorie == 'In Umsetzung'
            )?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Status","items":_vm.status},model:{value:(_vm.editedItem.properties.status),callback:function ($$v) {_vm.$set(_vm.editedItem.properties, "status", $$v)},expression:"editedItem.properties.status"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Anzahl der Ladepunkte","type":"number"},model:{value:(_vm.editedItem.properties.totalAmount),callback:function ($$v) {_vm.$set(_vm.editedItem.properties, "totalAmount", $$v)},expression:"editedItem.properties.totalAmount"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Gepl. Umsetzungsdatum","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.editedItem.properties.date),callback:function ($$v) {_vm.$set(_vm.editedItem.properties, "date", $$v)},expression:"editedItem.properties.date"}},'v-text-field',attrs,false),on))]}}],null,false,1735557929),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"de-DE","min":new Date().toISOString()},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.editedItem.properties.date),callback:function ($$v) {_vm.$set(_vm.editedItem.properties, "date", $$v)},expression:"editedItem.properties.date"}})],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('GmapMap',{ref:"googleMapRef",staticStyle:{"width":"100%","height":"350px"},attrs:{"map-type-id":_vm.$store.state.map.type,"zoom":_vm.mapProperties.defaultZoom,"center":_vm.markerPosition,"options":{
                  mapTypeControl: true,
                  streetViewControl: false,
                  styles: _vm.mapProperties.defaultStyle,
                }}},[_c('GmapMarker',{attrs:{"position":_vm.markerPosition,"clickable":true,"draggable":true},on:{"dragend":_vm.changeCoordinateHandler}}),(_vm.editedItem.objectType == 'Stempelgerät')?_c('GmapCircle',{attrs:{"center":_vm.markerPosition,"radius":parseFloat(_vm.editedItem.properties.radius),"visible":true,"options":{
                    fillColor: 'blue',
                    fillOpacity: 0.1,
                    strokeOpacity: 0,
                  }}}):_vm._e(),(_vm.editedItem.objectType == 'Stempelgerät')?_vm._l((_vm.stempelgeraete),function(object){return _c('GmapCircle',{key:'circle' + object.docid,attrs:{"radius":parseFloat(object.properties.radius),"visible":true,"center":{
                      lat: object.geometry.coordinates[1],
                      lng: object.geometry.coordinates[0],
                    },"options":{
                      fillColor: 'blue',
                      fillOpacity: 0.1,
                      strokeOpacity: 0,
                    }}})}):_vm._e()],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Latitude","value":_vm.markerPosition.lat,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Longitude","value":_vm.markerPosition.lng,"disabled":""}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.isNotValidForm},on:{"click":_vm.updateObject}},[_vm._v(" Speichern ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }