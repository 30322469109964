<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card>
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-title class="text-h6 pl-5">
          Objekt bearbeiten
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.label"
                  label="Label"
                  required
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="$store.getters.getSubCategories"
                  :loading="$store.state.rights.isLoading"
                  v-model="editedItem.properties.objectType"
                  label="Objekt Typ"
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="
                $store.getters.getMainCategory(editedItem.objectType) ==
                'Stempelgeraete'
              "
            >
              <v-col>
                <v-text-field
                  v-model.number="editedItem.properties.radius"
                  label="Radius (in meter)"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-if="
                $store.getters.getMainCategory(editedItem.objectType) ==
                'NEW Gebäude'
              "
            >
              <v-col>
                <v-textarea
                  v-model="editedItem.properties.description"
                  label="Beschreibung"
                  counter
                  maxlength="120"
                  full-width
                  single-line
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row
              v-if="
                this.editedItem.hauptKategorie == 'In Planung' ||
                this.editedItem.hauptKategorie == 'In Umsetzung'
              "
            >
              <v-col cols="4">
                <v-select
                  v-model="editedItem.properties.status"
                  label="Status"
                  :items="status"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.properties.totalAmount"
                  label="Anzahl der Ladepunkte"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Gepl. Umsetzungsdatum"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="editedItem.properties.date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.properties.date"
                    @input="menu = false"
                    locale="de-DE"
                    :min="new Date().toISOString()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <GmapMap
                  ref="googleMapRef"
                  style="width: 100%; height: 350px"
                  :map-type-id="$store.state.map.type"
                  :zoom="mapProperties.defaultZoom"
                  :center="markerPosition"
                  :options="{
                    mapTypeControl: true,
                    streetViewControl: false,
                    styles: mapProperties.defaultStyle,
                  }"
                >
                  <GmapMarker
                    :position="markerPosition"
                    :clickable="true"
                    :draggable="true"
                    @dragend="changeCoordinateHandler"
                  ></GmapMarker>
                  <GmapCircle
                    v-if="editedItem.objectType == 'Stempelgerät'"
                    :center="markerPosition"
                    :radius="parseFloat(editedItem.properties.radius)"
                    :visible="true"
                    :options="{
                      fillColor: 'blue',
                      fillOpacity: 0.1,
                      strokeOpacity: 0,
                    }"
                  ></GmapCircle>
                  <template v-if="editedItem.objectType == 'Stempelgerät'">
                    <GmapCircle
                      v-for="object in stempelgeraete"
                      :key="'circle' + object.docid"
                      :radius="parseFloat(object.properties.radius)"
                      :visible="true"
                      :center="{
                        lat: object.geometry.coordinates[1],
                        lng: object.geometry.coordinates[0],
                      }"
                      :options="{
                        fillColor: 'blue',
                        fillOpacity: 0.1,
                        strokeOpacity: 0,
                      }"
                    ></GmapCircle>
                  </template>
                </GmapMap>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  label="Latitude"
                  :value="markerPosition.lat"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  label="Longitude"
                  :value="markerPosition.lng"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog"> Abbrechen </v-btn>
        <v-btn
          color="primary"
          text
          @click="updateObject"
          :disabled="isNotValidForm"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    active: Number,
    object: Object,
  },

  data() {
    return {
      dialogVisible: false,
      editedItem: {
        geometry: {
          coordinates: [6.447762, 51.15777],
          type: "Point",
        },
        label: "",
        objectType: "",
        properties: {
          radius: 50,
          description: "",
        },
      },
      mapProperties: {
        defaultZoom: 14,
        defaultCenter: { lat: 51.15777, lng: 6.447762 },
        defaultStyle: [
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
      marker: null,
      rules: {
        required: (value) => !!value || "Required",
      },
      valid: true,
      status: ["In Planung", "In Umsetzung"],
      menu: false,
    };
  },

  watch: {
    active() {
      this.dialogVisible = true;
      this.editedItem = Object.assign({}, this.object);
      console.log("EDITEDITEM", this.editedItem);
    },

    object: {
      handler: function (val) {
        console.log(val);
      },
    },

    "$store.getters.getSubCategories": {
      handler: function (val) {
        if (val) {
          console.log("getSUBCATEGORIES", val);
        }
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    markerPosition() {
      if (this.marker) {
        return {
          lat: parseFloat(this.marker.latLng.lat().toFixed(6)),
          lng: parseFloat(this.marker.latLng.lng().toFixed(6)),
        };
      }
      return {
        lat: this.editedItem.geometry.coordinates[1],
        lng: this.editedItem.geometry.coordinates[0],
      };
    },

    stempelgeraete() {
      return this.$store.state.objects.objects.filter(
        (obj) => obj.objectType == "Stempelgerät"
      );
    },

    isNotValidForm() {
      if (!this.editedItem.objectType) return true;
      return false;
    },
  },

  methods: {
    closeDialog() {
      this.dialogVisible = false;
    },

    changeCoordinateHandler(position) {
      this.marker = position;
    },

    updateObject() {
      if (this.editedItem.label == "" || this.editedItem.objectType == "") {
        this.$store.commit("showSnackbar", {
          message: "Label und Objekttyp müssen ausgefüllt werden",
          color: "error",
        });
        return;
      }

      const data = {
        ...this.editedItem,
        //geometry: {
        //  coordinates: [
        //    parseFloat(this.markerPosition.lng),
        //    parseFloat(this.markerPosition.lat),
        //  ],
        //  type: "Point",
        //},
        //label: this.editedItem.label,
        //objectType: this.editedItem.objectType,
        //properties: {},
        //mainCategory: this.$store.getters.getMainCategory(
        //  this.editedItem.objectType
        //),
      };

      switch (this.$store.getters.getMainCategory(this.editedItem.objectType)) {
        case "Stempelgeraete":
          data.properties.radius = parseInt(this.editedItem.properties.radius);
          break;
        case "NEW Gebäude":
          data.properties.description = this.editedItem.properties.description;
          break;
        //case "In Umsetzung":
        //  data.properties = this.editedItem.properties;
        //  //data.objectType=
        //  break;
      }

      //  if (this.editedItem.properties.status == "In Planung") {
      //    data.mainCategory = this.editedItem.properties.status;
      //    data.objectType = `${this.editedItem.properties.status} - ${
      //      this.editedItem.properties.type == "AC"
      //        ? "Ladesäule"
      //        : "Schnellladesäule"
      //    }`;
      //  }

      if (
        this.editedItem.properties.status == "In Umsetzung" ||
        this.editedItem.properties.status == "In Planung"
      ) {
        data.hauptKategorie = this.editedItem.properties.status;
        data.mainCategory = this.editedItem.properties.status;
        data.objectType = `${this.editedItem.properties.status} - ${
          this.editedItem.properties.type == "AC"
            ? "Ladesäule"
            : "Schnellladesäule"
        }`;
        let icon =
          "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20AC.png?alt=media&token=1c8015a5-c27e-4317-bda5-fcaec382a519";
        if (this.editedItem.properties.type == "DC") {
          if (this.editedItem.properties.status == "In Planung") {
            icon =
              "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20DC%20Planung.png?alt=media&token=1c03d061-c0b9-4f62-a3ee-3f3a21397e00";
          } else {
            icon =
              "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20DC%20Umsetzung.png?alt=media&token=00ce4494-f68d-476a-acbf-a4f0bb64852d";
          }
        }
        data.icon = icon;
      }

      this.$store.dispatch("updateObject", {
        docid: this.object.docid,
        data,
      });

      this.closeDialog();
    },
  },

  created() {
    console.log(this.$store.state.objects.objects.length);
  },
};
</script>
